<template>
  <SlDialog title="尺码表" :visible.sync="dialogVisible" :width="dialogWidth">
    <div v-loading="loading">
      <template v-if="sizeImages.length">
        <div class="size-image-container">
          <SlImage class="mr-8px" v-for="url in sizeImages" size="10rem" :src="url" :key="url" />
        </div>
      </template>
      <SlTable
        v-else
        ref="table"
        align="left"
        :selection="false"
        :border="true"
        :tableData="tableData"
        :columns="columns"
        :operate="false"
        :tooltip="false"
        :isEmbedTable="true"
        maxHeight="320px"
      ></SlTable>
    </div>
    <template v-slot:bottom>
      <el-button @click="dialogVisible = false">关闭</el-button>
    </template>
  </SlDialog>
</template>
<script>
import { getPropsSafely } from '@shared/util'
import GoodsApi from '@api/goods'

export default {
  name: 'ProductSizeDialog',
  props: {},
  data () {
    return {
      loading: false,
      handleLoading: false,
      dialogVisible: false,
      dialogWidth: '350',
      tableData: [],
      columns: [],
      sizeImages: []
    }
  },
  methods: {
    openDialog ({ skuCode, spuCode }) {
      this.dialogVisible = true
      this.loading = true
      this.sizeImages = [] // 清掉数据
      GoodsApi.getProductSize({ skuCode, spuCode }).then(res => {
        let data = res.data || {}
        let { columnsArr, dataArr } = this.generateColumnsAndData(getPropsSafely(data, 'data.sizeDTO.sizeData.sizeData') || [])
        this.sizeImages = getPropsSafely(data, 'data.sizeDTO.sizeData.sizeImages') || []
        this.columns = columnsArr
        this.tableData = dataArr
      }).finally(() => {
        this.loading = false
      })
    },
    // 生成表格列数据和表格数据
    generateColumnsAndData (data) {
      let columnsArr = [
        {
          prop: 'sizeName',
          label: '尺码'
        }
      ]
      let dataArr = (data || []).reduce((acc, cur, idx) => {
        // 平铺各国家标准尺码数据
        let standerSizeObj = (cur.standerSizeData || []).reduce((acc0, cur0) => {
          if (idx === 0) {
            columnsArr.push({
              prop: cur0.standerSizeCode,
              label: cur0.standerSizeCode
            })
          }
          acc0[cur0.standerSizeCode] = cur0.standerSizeValue
          return acc0
        }, {})

        // 平铺每个尺码部位的数据
        let positionObj = (cur.positionData || []).reduce((acc1, cur1, idx1) => {
          let positionKey = `position_${idx1}`
          if (idx === 0) {
            columnsArr.push({
              prop: positionKey,
              label: `${cur1.positionName}${cur1.positionDesc ? '(' + cur1.positionDesc + ')' : ''}`
            })
          }
          acc1[positionKey] = cur1.measurementValue
          return acc1
        }, {})

        acc.push({
          sizeName: cur.sizeName,
          ...standerSizeObj,
          ...positionObj
        })
        return acc
      }, [])

      return {
        dataArr,
        columnsArr
      }
    }
  }
}
</script>
<style lang="scss">
</style>
