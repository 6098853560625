
<template>
  <div>
    <SlListView
      ref="listView"
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
      :pageSizes="pageSizes"
    >
      <div slot="search">
        <!-- 搜索区域search包含搜索和重置按钮 -->
        <SlSearchForm
          ref="searchForm"
          v-model="formQuery"
          :items="searchItems"
          :loading="tableLoading"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>
      </div>
      <SlTableToolbar>
        <SlButton
          type="primary"
          boxShadow="primary"
          :loading="loading"
          :disabled="selections.length === 0"
          @click="updateStatus(selections,'multiple')"
        >批量更新供货状态</SlButton>
      </SlTableToolbar>
      <!-- 表格区域包含分页 -->
      <SlTable
        ref="table"
        v-model="selections"
        :tableData="tableData"
        :columns="columns"
        :operate="true"
        :tooltip="false"
        rowKey="id"
      >
        <div slot="operation" slot-scope="{row}">
          <el-button class="operation-btn" type="text" @click="viewProductSize(row)">尺码表</el-button>
        </div>
      </SlTable>
    </SlListView>

    <SlDialog
      title="你确定要批量更新供货状态?"
      :visible.sync="confirmDialogVisible"
      @submit="submitConfirm"
    >{{confirmMessage}}</SlDialog>

    <!-- 尺码表对话框 -->
    <ProductSizeDialog ref="productSizeDialog"></ProductSizeDialog>
  </div>
</template>

<script>
import GoodsApi from '@api/goods'
import { errorMessageTip } from '@shared/util'
import ProductSizeDialog from './productSkuList/ProductSizeDialog.vue'

export default {
  name: 'ProductSkuList',
  components: {
    ProductSizeDialog
  },
  props: {
  },
  data () {
    return {
      tableLoading: false,
      loading: false,
      confirmDialogVisible: false,
      tableData: [],
      selections: [],
      submitData: {
        idList: [],
        supplierSkuStatusEnum: null
      },
      formQuery: {
      },
      page: {
        pageIndex: 1,
        pageSize: 50,
        total: 0
      },
      pageSizes: [10, 20, 50, 100, 200],
      searchItems: [
        {
          type: 'batch-input',
          label: 'SPU',
          name: 'spuCodeStr'
        },
        {
          type: 'input',
          label: 'SKU',
          name: 'skuCode'
        },
        {
          type: 'batch-input',
          label: '商家SKU',
          name: 'supplierSkuCodeStr'
        },
        {
          type: 'batch-input',
          label: '供方货号',
          name: 'supplierProductNoStr'
        }
      ],
      columns: [
        {
          prop: 'spuCode',
          label: 'SPU'
        },
        {
          prop: 'imageSrc',
          label: '商品图片',
          isImg: true,
          data: {
            imgSize: '5rem'
          }
        },
        {
          prop: 'productName',
          label: '商品名称',
          width: '225',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <el-tooltip placement="top" effect="light">
                <div slot="content" style="max-width:300px">{row.productName}</div>
                <p v-slClamp={{ clamp: 2 }}>{row.productName}</p>
              </el-tooltip>
            )
          }
        },
        {
          prop: 'categoryName',
          label: '分类',
          width: '150'
        },
        {
          prop: 'skuCode',
          label: 'SKU编号'
        },
        {
          prop: 'color',
          label: '颜色'
        },
        {
          prop: 'size',
          label: '尺码'
        },
        {
          prop: 'specifications',
          label: '规格'
        },
        {
          prop: 'purchasePrice',
          label: '供货价格'
        },
        {
          prop: 'supplierProductNo',
          label: '供方货号'
        },
        {
          prop: 'supplierSkuCode',
          label: '商家SKU编码'
        },
        {
          prop: 'supplierTagSize',
          label: '商家吊牌尺码'
        },
        {
          prop: 'supplierSkuStatusName',
          label: '供货状态',
          width: 150,
          render: (h, data) => {
            let { row = {} } = data
            let map = {
              0: '缺货',
              1: '正常'
            }
            return (
              <div>
                <SlSwitchButton value={row.supplierSkuStatus} onChange={() => this.updateStatus([row], 'one')} active-value={1}
                  inactive-value={0} disabled={this.loading}></SlSwitchButton>&nbsp;
                {map[row.supplierSkuStatus]}
              </div>
            )
          }
        }
      ],
      confirmMessage: ''
    }
  },
  methods: {
    gotoPage (pageSize = 50, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      GoodsApi.getSupplierSkuList(params).then(res => {
        let { success, data = {} } = res
        if (success) {
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      let { ...orther } = this.formQuery
      return {
        ...orther,
        pageIndex,
        pageSize
      }
    },
    // type:'one' or 'multiple'-批量修改
    updateStatus (data = [], type) {
      const MSG_0 = '更新后供货状态将由"缺货"变更为"正常"。'
      const MSG_1 = '更新后供货状态将由"正常"变更为"缺货"。'
      const length = data.length
      let outOfStockNumber = 0
      let normalNumber = 0

      data.forEach(item => {
        if (item.supplierSkuStatus === 0) {
          outOfStockNumber++
        } else {
          normalNumber++
        }
      })

      if (length > 0 && normalNumber !== length && outOfStockNumber !== length) {
        errorMessageTip('仅允许批量更新相同供货状态的数据')
        return
      }

      if (outOfStockNumber > 0 && outOfStockNumber === length) {
        this.confirmMessage = MSG_0
      }

      if (normalNumber > 0 && normalNumber === length) {
        this.confirmMessage = MSG_1
      }
      this.updateWay = type
      this.submitData.idList = data.map(item => item.id)
      this.submitData.supplierSkuStatusEnum = outOfStockNumber > 0 ? 1 : 0
      this.confirmDialogVisible = true
    },
    submitConfirm () {
      this.confirmDialogVisible = false
      this.loading = true
      GoodsApi.updateSupplierSkuStatus(this.submitData).then((res) => {
        let { success } = res
        if (success) {
          this.$message.success('操作成功！')
          this.submitData.idList = []
          this.submitData.supplierSkuStatusEnum = null
          this.selections = []
          this.gotoPage(this.page.pageSize, 1)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    viewProductSize (row) {
      this.$refs.productSizeDialog.openDialog(row)
    }

  }

}
</script>

<style scoped lang="scss">
</style>
